import React, { Fragment, useEffect, useState } from 'react'
import JwtTokenInfo from '../types/JwtTokenInfo';
import dateFormat from 'dateformat';
import "./SessionExpirationBanner.css"

const SessionExpirationBanner: React.FC = () => {

    const [jwtInfo, setJwtInfo] = useState<JwtTokenInfo|undefined>(undefined);

    useEffect(() => {
        const jwtInfoRaw = localStorage.getItem("jwtInfo");
    
        if (jwtInfoRaw && jwtInfoRaw !== '') {
            const jwt = JSON.parse(jwtInfoRaw) as JwtTokenInfo;
            setJwtInfo(jwt);
        }
      }, [setJwtInfo]);

    return (
        <Fragment>
            <div className='bottomBanner'>{jwtInfo ? `Session will expire at ${dateFormat(jwtInfo.Expires, "mm/dd/yyyy h:MM TT")}` : ''}</div>
        </Fragment>
    )
}

export default SessionExpirationBanner;