import React from "react";
import AuthenticatedUser from "./types/AuthenticatedUser";


export interface IInitialState {
    AuthenticatedUser: AuthenticatedUser | undefined;
    OpenUploadModal: boolean;
    IsLoggedIn: boolean;
}

export const initialState = {
    AuthenticatedUser: undefined,
    OpenUploadModal: false,
    IsLoggedIn: false
} as IInitialState

export const types = {
    SETAUTHENTICATEDUSER: "SETAUTHENTICATEDUSER",
    SETOPENUPLOADMODAL: 'SETOPENUPLOADMODAL',
    SETISLOGGEDIN: 'SETISLOGGEDIN'
}

export const AppContext = React.createContext(initialState as any);

export const reducer = (state: any, action: any) => {
    switch (action.type) {
        case types.SETAUTHENTICATEDUSER: {
            return { ...state, AuthenticatedUser: action.AuthenticatedUser };
        }
        case types.SETOPENUPLOADMODAL: {
            return { ...state, OpenUploadModal: action.OpenUploadModal };
        }
        case types.SETISLOGGEDIN: {
            return { ...state, IsLoggedIn: action.IsLoggedIn };
        }
    }
}