import dateFormat from "dateformat";
import React, { useState } from "react";
import {
  Divider,
  List,
  Container,
  Header,
  Icon,
  Item,
} from "semantic-ui-react";
import agent from "../../api/agent";
import { CustomerPayment } from "./AdminPortalTypes";


 export default function PaymentsCompleted() {

    const [completedPayments, setCompletedPayments] = useState<any>();

    React.useEffect(() => {
      agent.Payments.payments("SUBMITTED").then(list => setCompletedPayments(list))  //reloads API call to get pending payments if the modal is closed
    }, [])

    
    const displayPaymentList = completedPayments ? completedPayments.submissions.map((properties: CustomerPayment) => {
      return   <><Divider horizontal>
                    <Icon name="first order"></Icon>
                 </Divider>
                  <Item>
                    <Item.Content>
                      <Item.Header>Submitted Date: {dateFormat(properties.submissionDate, "mm/dd/yyyy h:MM TT")}</Item.Header>
                   
                        <Item.Description>
                          Processed Date: {dateFormat(properties.processedDate, "mm/dd/yyyy h:MM TT")}
                        </Item.Description> : 
                      
                      
                        <Item.Description>
                          Processed By: {properties.processedBy}
                        </Item.Description> 
                      
                      <Item.Meta>Customer number: {properties.customerNumber}</Item.Meta>

                      <Item.Description>
                        Order number: {properties.orderNumber}
                      </Item.Description>
                      <Item.Description>
                        Email: {properties.emailAddress}
                      </Item.Description>
                      <Item.Description>
                        Billing Address: {properties.billingAddress}
                      </Item.Description>
                      <Item.Description>
                        Phone: {properties.phoneNumber}
                      </Item.Description>

                      <Item.Extra>
                      </Item.Extra>
                    </Item.Content>
                  </Item></>

 

  }) : "";
  console.log(completedPayments);
  
    return (
      <>
       <Header textAlign="center">Payments Submitted</Header>
       <Container textAlign="center">
        <List>
            {displayPaymentList}
        </List>
       </Container>
      </>
    );
  };
  