import AuthenticatedUser from "../types/AuthenticatedUser";

const AuthenticatedUserUtil = {
    MapFromRaw: (auRaw: any) => {
        return {
            Username: auRaw.username,
            DisplayName: auRaw.displayName,
            IsAuthenticated: auRaw.isAuthenticated,
            Groups: auRaw.groups,
            Token: auRaw.token,
            IsAdmin: auRaw.isAdmin,
            IsSuperAdmin: auRaw.isSuperAdmin,
            PermissionGroup: auRaw.PermissionGroup
        } as AuthenticatedUser
    }
}

export default AuthenticatedUserUtil;