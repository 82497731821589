import jwt_decode from "jwt-decode";
import JwtTokenInfo from "../types/JwtTokenInfo";

const JwtTokenInfoUtil = {
    MapFromToken: (token: string) => {
        const jwt = jwt_decode(token) as any;
        return {
            UserId: jwt.unique_name,
            DisplayName: jwt.nameid,
            Permission: jwt.Permission,
            PermissionGroup: jwt.PermissionGroup,
            Token: token,
            Expires: new Date(jwt.exp * 1000)
        } as JwtTokenInfo;
    },
    RetrieveFromCache: () => {
        const jwtInfoRaw = localStorage.getItem("jwtInfo");
        if(jwtInfoRaw && jwtInfoRaw !== '') {
            return JSON.parse(jwtInfoRaw) as JwtTokenInfo;
        }
    },
    IsSuperAdmin: () => {
        const jwtUser = JwtTokenInfoUtil.RetrieveFromCache();
        if(jwtUser) {
            return jwtUser.Permission === 'SuperAdmin'
        } else {
            return false;
        }
    },
    IsAdmin: () => {
        const jwtUser = JwtTokenInfoUtil.RetrieveFromCache();
        if(jwtUser) {
            return jwtUser.Permission === 'Admin'
        } else {
            return false;
        }
    },
    IsRO: () => {
        const jwtUser = JwtTokenInfoUtil.RetrieveFromCache();
        if(jwtUser) {
            return jwtUser.Permission === 'RO'
        } else {
            return false;
        }
    }
}

export default JwtTokenInfoUtil;