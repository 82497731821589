import React, { Fragment, useEffect, useReducer } from 'react';
import axios, { Axios, AxiosInstance, AxiosResponse, AxiosStatic } from "axios";
import './App.scss';
import { reducer, initialState, AppContext } from './store';
import { Router, Switch, Route, useHistory, useLocation } from "react-router-dom";
//import agent from './api/agent';
import { ToastContainer } from 'react-toastify';
//import LoginModal from './pages/Login/LoginModal';
import {createBrowserHistory} from 'history';
import { Image, Divider } from 'semantic-ui-react'
import SessionExpirationBanner from './layout/SessionExpirationBanner';
//import { ApmRoute } from '@elastic/apm-rum-react'
import Payment from './pages/Payment/Payment';
import PaymentSubmitted from './pages/PaymentSubmitted/PaymentSubmitted';
import AdminPortal from './pages/Admin/Admin';
import agent from './api/agent'
import LoginModal from './pages/Login/LoginModal';


export const App = () => {

  const [state, dispatch] = useReducer(reducer, initialState);  

  useEffect(() => {
    if (window.location.pathname === "/") {
      history.push("/PaymentPortal");
    }
  });

  return (
    <Fragment>
      <ToastContainer position="bottom-right" />
      <Image style={{paddingTop: '25px'}} size='medium' centered src='/OWC_Logo2.png'/>
      <Divider horizontal>OWC Payment Portal</Divider>
      <AppContext.Provider value={{ state, dispatch }}> 
        <Router history={history}>
          <Switch>
            <Route exact path="/PaymentPortal" component={Payment} />
            <Route exact path="/PaymentSubmitted" component={PaymentSubmitted} /> 
            <Route exact path="/AdminPortal" component={AdminPortal} />
            <Route exact path="/login" component={LoginModal} />
            {/* <ApmRoute exact path="/" component={SomeNewComponent} /> */}
          </Switch>
        </Router>
      </AppContext.Provider>
    </Fragment>
  );
}

export const history = createBrowserHistory();