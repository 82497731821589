import React, { useState, useEffect, ReactFragment } from "react";
import {
  Card,
  Header,
  Menu,
  MenuItemProps
} from "semantic-ui-react";
import PaymentsCompleted from "../PaymentsListed/PaymentsCompleted";
import PaymentsPending from "../PaymentsListed/PaymentsPending";
import SessionExpirationBanner from "../../layout/SessionExpirationBanner";
import agent from '../../api/agent'
import UrlGenerator from "./UrlGenerator";
import JwtTokenInfo from "../../types/JwtTokenInfo";
 export default function AdminPortal() {

  useEffect(() => {
    agent.Login.quickAuthorizationCheck();
    }, []);
    var permGroup = "restricted";
    var token = localStorage.getItem("jwtInfo");
    if(token && token!==""){
      permGroup = (JSON.parse(token) as JwtTokenInfo).PermissionGroup;
    }
    

    const [activeItem, setActiveItem] = useState<string | undefined>("URL Generator");
    const [activeView, setActiveView] = useState<ReactFragment>();

    const handleItemClick = (e: React.MouseEvent, data: MenuItemProps) => {    
      
        setActiveItem(data.name)
        
    }

    React.useEffect(() => { //sets view component depending on which menu item is clicked. 

                switch(activeItem)  {

                case "submitted":
                setActiveView(<PaymentsCompleted />) 
                break;
    
                case "pending":
                setActiveView(<PaymentsPending />)
                break;

                case "URL Generator":
                setActiveView(<UrlGenerator></UrlGenerator>)
                break;
    
             }
        }, [activeItem]
    )

    if(permGroup==="restricted"){
      return (
        <>
         <Header textAlign="center">Hello! Welcome to the Admin Portal.</Header>
  
         <Menu widths={3}>
          <Menu.Item
            name='URL Generator'
            //active={activeItem === 'submitted'}
            onClick={handleItemClick}
          />
        </Menu>
        <Card.Group centered>
            <Card centered style={{ padding: "25px", width: "700px" }}>
                  {activeView}
            </Card>
        </Card.Group>  
        <SessionExpirationBanner />
        </>
      );
    }
    return (
      <>
       <Header textAlign="center">Hello! Welcome to the Admin Portal.</Header>

       <Menu widths={3}>
        <Menu.Item
          name='pending'
          //active={activeItem === 'pending'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='submitted'
          //active={activeItem === 'submitted'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='URL Generator'
          //active={activeItem === 'submitted'}
          onClick={handleItemClick}
        />
      </Menu>
      <Card.Group centered>
          <Card centered style={{ padding: "25px", width: "700px" }}>
                {activeView}
          </Card>
      </Card.Group>  
      <SessionExpirationBanner />
      </>
    );
  };
  
  