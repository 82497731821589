import React, { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Form,
  Divider,
  InputOnChangeData,
  Label,
  DropdownProps,
} from "semantic-ui-react";
 import agent from "../../api/agent";
 import PaymentFailure from "../PaymentSubmitted/PaymentFailure";
//import "../PaymentSubmitted/PaymentSubmitted"

export default function PaymentInputs() {
  const currentYear = new Date().getFullYear();
  const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);
  const monthsArr = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

  const monthsList = monthsArr.map((month, id) => {
    return {key: id, text: month, value: month}
  })
  const yearsList = yearsArr.map((year, id) => {
    return {key: id, text: year, value: year}
  })


  const queryString = new URLSearchParams(window.location.search)
  //console.log(queryString.get("orderid"), "test")

  let history = useHistory();
  const [failureMsg, setFailureMsg] = React.useState<string>("");

  /*const [cardHolder, setCardHolder] = useState("");
  const handleCardHolderChange = (_: any, data: InputOnChangeData) => {
    setCardHolder(data.value);
  };*/

  const[cardExpireMonth, setCardExpireMonth] = useState<any>("");
  const handleCardExpireMonthChange = (_: any, data: DropdownProps) => {
    setCardExpireMonth(data.value);
  };

  const[cardExpireYear, setCardExpireYear] = useState<any>("");
  const handleCardExpireYearChange = (_: any, data: DropdownProps) => {
    setCardExpireYear(data.value);
  };

  const [cardNumber, setCardNumber] = useState("");
    const handleCardNumberChange = (_: any, data: InputOnChangeData) => {
    let value = data.value;
    let cardNumber = data.value;

    value = value.replace(/\D/g, "");
    if (/^3[47]\d{0,13}$/.test(value)) {
      cardNumber = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
      // diner's club, 14 digits
      cardNumber = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (/^\d{0,16}$/.test(value)) {
      // regular cc number, 16 digits
      cardNumber = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
        .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
    }
    setCardNumber(cardNumber.trimEnd());
  };
 

  const submitClicked = async (event: FormEvent<HTMLFormElement>) => {

      // Securely Persist the data to database
      //console.log(typeof(event.target));
      const data = new FormData(event.currentTarget);
      data.set('cardNumber', cardNumber.replace(/\s/g, ''))
      data.append('cardExpireMonth', cardExpireMonth)
      data.append('cardExpireYear', cardExpireYear)

      var result = await agent.Upload.upload(data);

      console.log(result);

      if (result.success === true) {
          history.replace('/PaymentSubmitted')
      }
      else {
        setFailureMsg(result.message)
      }
  };

  return (
    <>
      {failureMsg ? <PaymentFailure errorMsg={failureMsg} /> : ""}
      <Card centered style={{ marginTop: "25px", width: "400px" }}>
        <Card.Content textAlign="center" header="OWC Payment Portal" />
        <Card.Content>
          <Form id="form" widths="equal" onSubmit={submitClicked}>
          <Form.Group>
              <Form.Input
                label="Order Number"
                name="orderNumber"
                value={queryString.get("orderNumber")}
                placeholder=""
                transparent
                readOnly
              />
              <Form.Input
                label="Customer Number"
                name="customerCode"
                value={queryString.get("customerNumber")}
                placeholder=""
                transparent
                readOnly
              />
            </Form.Group>
            <Label circular basic style={{ width: "100%" }} color="blue">
              Card Holder Info
            </Label>
            <Form.Group grouped>
              <Form.Input
                label="Name on Card"
                name="cardHolder"
                //value={cardHolder}
                //ref={cardHolderRef}
                placeholder="Card Name"
                //onChange={handleCardHolderChange}
                required
              />
              <Form.Input
                label="Email"
                name="cardEmail"
                //value={cardEmail}
                placeholder="Email"
                //onChange={handleCardEmailChange}
                //ref={cardEmail}
                type="email"
                prompt="Please enter a valid e-mail"
                required
              />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Phone Number"
                 // value={cardPhone}
                  name="cardPhone"
                  //ref={cardPhone}
                  placeholder="Phone Number"
                  type="number"
                  control="input"
                 // onChange={handleCardPhoneChange}
                  width={7}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Billing Address"
                  name="billingAddress"
                  placeholder="Billing Address"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Billing Address Two"
                  name="billingAddress2"
                  placeholder="Billing Address Two (if applicable)"
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Company Name"
                  name="companyName"
                  placeholder="Company Name (if applicable)"
                />
                <Form.Input
                  label="Zip Code"
                  //value={cardZip}
                  name="cardZip"
                  //ref={cardZip}
                  placeholder="Zip Code"
                  //onChange={handleCardZipChange}
                  width={6}
                  required
                />
            </Form.Group>
            <Label circular basic style={{ width: "100%" }} color="blue">
              Credit Card Info
            </Label>
            <Form.Group grouped>
              <Form.Input
                fluid
                label="Card Number"
                value={cardNumber}
                //name="cardNumber"
                placeholder="Card Number"
                onChange={handleCardNumberChange}
                maxLength="19"
                type="creditCard"
                prompt="Please enter a valid credit card"
                minLength="15"
                required
              />
              <Form.Group>
                <Form.Select
                  options={monthsList}
                  fluid
                  label="Expire Month"
                  value={cardExpireMonth}
                  name="cardExpireMonth"
                  //ref={cardExpireMonth}
                  placeholder="MM"
                  onChange={handleCardExpireMonthChange}
                  maxLength="2"
                  width={4}
                  list="MonthList"
                  required
                />

                <Form.Select
                  fluid
                  options={yearsList}
                  label="Expire Year"
                  value={cardExpireYear}
                  name="cardExpireYear"
                  //ref={cardExpireYear}
                  placeholder="YYYY"
                  onChange={handleCardExpireYearChange}
                  maxLength="4"
                  width={4}
                  list="YearList"
                  required
                />

                <Form.Input
                  fluid
                  label="CVV"
                  //value={cardCvv}
                  name="cardCvv"
                  //ref={cardCvv}
                  placeholder="***"
                  //onChange={handleCardCvvChange}
                  maxLength="4"
                  width={4}
                  required
                />
              </Form.Group>
            </Form.Group>
            <Divider />
            <Form.Button
              fluid
              type="submit"
              onClick={e => console.log(e.target)}
              color="blue"
            >
              Submit Payment Request
            </Form.Button>
          </Form>
        </Card.Content>
      </Card>
    </>
  );
}
