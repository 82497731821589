import { Container, Header } from "semantic-ui-react";

export default function PaymentSubmitted() {
  return (
    <Container text>
      <div>
        <Header textAlign="center" as="h1">OWC Payment Portal</Header>
        <Header textAlign="center" as="h3">
          Thank you for submitting your payment information.
        </Header>
      </div>
    </Container>
  );
}
