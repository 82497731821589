import React, { FormEvent } from "react";
import {
  Card,
  Form,
  CardContent
} from "semantic-ui-react";
import agent from '../../api/agent'

export default function UrlGenerator() {

    type Url = {
        success: true,
        message: string,
        url: string
    }

    const [customerUrl, setCustomerUrl] = React.useState<string | Url>("")

    const submitClicked = async (event: FormEvent<HTMLFormElement>) => {

        const data = new FormData(event.currentTarget)
        const customerNumber = Object.fromEntries(data).customerNo
        const orderNumber = Object.fromEntries(data).orderNo

        var result = await agent.Customer.checkOrder(data);

        if (result.success === true) {
            await agent.Customer.generateUrl(customerNumber, orderNumber).then(data => setCustomerUrl(data))
        }
        else {
          setCustomerUrl("There are no customers matching the information you have entered. Please verify your entries and try again.")
        }

    }

    console.log(customerUrl)
    return (
        <>
            <Card centered style={{ display: "inline-block", width: "350px" }}>
                <Card.Content>
                    <Form onSubmit={submitClicked}>
                    <Form.Group grouped>
                        <Form.Input
                            label="Order Number"
                            name="orderNo"
                            placeholder=""
                            required
                        />
                        <Form.Input
                            label="Customer Number"
                            name="customerNo"
                            placeholder="Customer Number"
                            required
                        />
                    </Form.Group>
                    <Form.Button
                        fluid
                        type="submit"
                        onClick={e => console.log(e.target)}
                        color="blue"
                        >
                        Submit
                        </Form.Button>
                    </Form>
                </Card.Content>
            </Card>
            <Card centered style={{ display: "inline-block", width: "700px" }}>
                <CardContent>
                    URL for customer:
                </CardContent>
                <CardContent>
                    {typeof(customerUrl) === "string" ? customerUrl : customerUrl.url /*check if error is returned*/} 
                </CardContent>
            </Card>
        
        </>
    )
}