import React, { useState, useRef } from "react";
import {
  Divider,
  Container,
  Header,
  Button,
  Modal,
  Item,
  Icon,
  ButtonProps,
} from "semantic-ui-react";
import dateFormat from 'dateformat';
import agent from "../../api/agent";
import { CustomerPayment } from "./AdminPortalTypes";


 const PaymentsPending: React.FC = () => {

    const [open, setOpen] = React.useState(false)
    const [orderId, setOrderId] = React.useState("")
    const [customerInfo, setCustomerInfo] = useState<any>();
    const [pendingPayments, setPendingPayments] = useState<any>();
    const isInitialMount = useRef(true);

    const cardType = (cardNumber: string) => { //I would have liked to do this in a switch, but switch cases only take strict equality for boolean values and nothing else.

      const cards = [
        {
          firstNum: 3,
          name: "American Express"
        },
        {
          firstNum: 4,
          name: "Visa"
        },
        {
          firstNum: 5,
          name: "Mastercard"
        },
        {
          firstNum: 6,
          name: "Discover"
        },
      ]

      let cardType = cards.find((card: any) => card.firstNum == cardNumber.charAt(0)) //if first number of the enterec card matches one of the card objects, return the card's name.
      return cardType?.name ? cardType.name : "Other"

    }

    const handleItemClick = (e: React.MouseEvent, data: ButtonProps) => {
       setOrderId(data.id);
    }

    const handleClose = (e: React.MouseEvent, data: ButtonProps) => {
      setCustomerInfo("");
      setOpen(false);
    }

    const handleApprovalClick = async (e: React.MouseEvent, data: ButtonProps) => {
      await agent.Payments.approvePayment(data.id, "SUBMITTED");
      setOpen(false);
      window.location.reload(); // have to find another way around this rerender other than reloading the page
    }

    React.useEffect(() => {
       agent.Payments.payments("PENDING").then(list => setPendingPayments(list))  //reloads API call to get pending payments if the modal is closed
    }, [open])

    React.useEffect(() => { //this useEffect runs on every iteration other than the very first render. I tried to put the modal in a different file initially, but it doesn't seem to like that it can't reference its parent
                            // element in order to display.

      if (isInitialMount.current) {
          isInitialMount.current = false;
      } else {
        agent.Payments.paymentsId(orderId).then(data => setCustomerInfo(data.submissions)) //unencrypted data of customer
      }
          
      }, [orderId]
    )


    const displayPaymentList = pendingPayments ? pendingPayments.submissions.map((properties: CustomerPayment) => {
        return   <><Divider horizontal>
                      <Icon name="first order"></Icon>
                   </Divider>
                    <Item>
                      <Item.Content>
                        <Item.Header>{dateFormat(properties.submissionDate, "mm/dd/yyyy h:MM TT")}</Item.Header>
                        <Item.Meta>Customer number: {properties.customerNumber}</Item.Meta>

                        <Item.Description>
                          Order number: {properties.orderNumber}
                        </Item.Description>
                        <Item.Description>
                          Email: {properties.emailAddress}
                        </Item.Description>
                        <Item.Description>
                          Billing Address: {properties.billingAddress}
                        </Item.Description>
                        <Item.Description>
                          Zip Code: {properties.zipCode}
                        </Item.Description>
                        {properties.billingAddress2 ? 
                        <Item.Description>
                          Billing Address 2: {properties.billingAddress2}
                        </Item.Description> : ""
                        }

                        <Item.Description>
                          Phone: {properties.phoneNumber}
                        </Item.Description>

                        <Item.Extra>
                          <Modal
                            closeIcon
                            open={open}
                            trigger={<Button id={properties.id} onClick={handleItemClick}>Show Payment Information</Button>}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                          >
                            <Header icon='archive' content={`Payment Information for customer ${properties.customerNumber}`} />
                            <Modal.Content>
                                Card Number: {customerInfo ? customerInfo[0].cardNumber : ""}                            
                            </Modal.Content>
                            <Modal.Content>
                                Type of card: {customerInfo ? cardType(customerInfo[0].cardNumber) : ""}        
                            </Modal.Content>
                            <Modal.Content>                            
                                Name on Card: {customerInfo ? customerInfo[0].nameOnCard : ""}      
                            </Modal.Content>
                            <Modal.Content>                            
                                Expiration Date: {customerInfo ? customerInfo[0].expireMonth : ""}/{customerInfo ? customerInfo[0].expireYear : ""}      
                            </Modal.Content>
                            <Modal.Content>                            
                                CVC: {customerInfo ? customerInfo[0].cvc : ""}      
                            </Modal.Content>
                            <Modal.Actions>
                              <Button color='green' loading={!customerInfo} disabled={!customerInfo} id={customerInfo ? customerInfo[0].id : ""} onClick={handleApprovalClick}>
                                <Icon name='checkmark' /> Approve
                              </Button>
                              <Button color='red' onClick={handleClose}>
                                <Icon name='remove' /> Close
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        </Item.Extra>
                      </Item.Content>
                    </Item></>

   
 
    }) : ""; // this, is admittedly, a very ghetto solution. We can't stop useEffect from running at the end of component run time, but we can't launch it at the beginning either, because it will re-render continuously.
             
    
  
    return (
      <>
       <Header textAlign="center">Payments Pending</Header>
       <Container textAlign="center">
        <Item.Group>
            {displayPaymentList}
        </Item.Group>
       </Container>
     </>
    );
  };
  
  export default PaymentsPending